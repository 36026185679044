export const PRICE_TABLE_ID = "pricing-table";

export const paymentFrequencyOptions = [
  { value: "monthly", label: "pricing:payment_frequency_switch:monthly" },
  { value: "yearly", label: "pricing:payment_frequency_switch:yearly" },
];

export const FREE_PLAN_ID = `FREE WITH ADS`;

export const PRICE_PER_EXTRA_USER = 500;
export const DEFAULT_CURRENCY = "EUR";
export const PLANS_WITH_MULTI_USERS = ["PRO", "ENTERPRISE"];

export const plansOrder = [
  // "FREE WITH ADS",
  "LIGHT FARE AND NO ADS",
  "LIGHT PRO",
  "PRO",
  "ENTERPRISE",
];

export const individualPlansMonthly = [
  // {
  //   value: "FREE WITH ADS",
  //   popular: false,
  //   label: "FREE WITH ADS",
  //   price: 0,
  //   has_sale: false,
  //   has_toggle: false,
  //   no_credit_card: true,
  //   multi_users: {
  //     enabled: false,
  //   },
  //   description: "pricing:plan:FREE WITH ADS:description",
  //   qr_codes: "pricing:plan:dynamic_qr_code_with_ads",
  //   qr_codes_count: 1,
  //   included: [
  //     "pricing:plan:FREE WITH ADS:included1",
  //     "pricing:plan:FREE WITH ADS:included2",
  //   ],
  //   not_included: ["pricing:plan:FREE WITH ADS:not_included1"],
  //   button: {
  //     type: "link",
  //     label: "pricing:get_started",
  //   },
  // },
  {
    value: "LIGHT FARE AND NO ADS",
    popular: false,
    label: "LIGHT FARE AND NO ADS",
    price: 6,
    has_sale: false,
    has_toggle: true,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT FARE AND NO ADS:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 3,
    included: [
      "pricing:plan:LIGHT FARE AND NO ADS:included1",
      "pricing:plan:LIGHT FARE AND NO ADS:included2",
    ],
    not_included: ["pricing:plan:LIGHT FARE AND NO ADS:not_included1"],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "LIGHT PRO",
    popular: true,
    label: "LIGHT PRO",
    price: 30,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 50,
    included: [
      "pricing:plan:LIGHT PRO:included1",
      "pricing:plan:LIGHT PRO:included2",
      "pricing:plan:LIGHT PRO:included3",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "PRO",
    popular: false,
    label: "PRO",
    price: 99,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: true,
      custom: false,
      min: 3,
      max: 150,
    },
    description: "pricing:plan:PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 250,
    included: [
      "pricing:plan:PRO:included1",
      "pricing:plan:PRO:included2",
      "pricing:plan:PRO:included3",
      "pricing:plan:PRO:included4",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
];

export const individualPlansYearly = [
  // {
  //   value: "FREE WITH ADS",
  //   popular: false,
  //   label: "FREE WITH ADS",
  //   price: 0,
  //   has_sale: false,
  //   has_toggle: false,
  //   no_credit_card: true,
  //   multi_users: {
  //     enabled: false,
  //   },
  //   description: "pricing:plan:FREE WITH ADS:description",
  //   qr_codes: "pricing:plan:dynamic_qr_code_with_ads",
  //   qr_codes_count: 1,
  //   included: [
  //     "pricing:plan:FREE WITH ADS:included1",
  //     "pricing:plan:FREE WITH ADS:included2",
  //   ],
  //   not_included: ["pricing:plan:FREE WITH ADS:not_included1"],
  //   button: {
  //     type: "link",
  //     label: "pricing:get_started",
  //   },
  // },
  {
    value: "LIGHT FARE AND NO ADS",
    popular: false,
    label: "LIGHT FARE AND NO ADS",
    price: 5,
    has_sale: true,
    sale: 12,
    has_toggle: true,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT FARE AND NO ADS:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 3,
    included: [
      "pricing:plan:LIGHT FARE AND NO ADS:included1",
      "pricing:plan:LIGHT FARE AND NO ADS:included2",
    ],
    not_included: ["pricing:plan:LIGHT FARE AND NO ADS:not_included1"],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "LIGHT PRO",
    popular: true,
    label: "LIGHT PRO",
    price: 15,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 50,
    included: [
      "pricing:plan:LIGHT PRO:included1",
      "pricing:plan:LIGHT PRO:included2",
      "pricing:plan:LIGHT PRO:included3",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "PRO",
    popular: false,
    label: "PRO",
    price: 49,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: true,
      custom: false,
      min: 3,
      max: 150,
    },
    description: "pricing:plan:PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 250,
    included: [
      "pricing:plan:PRO:included1",
      "pricing:plan:PRO:included2",
      "pricing:plan:PRO:included3",
      "pricing:plan:PRO:included4",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
];

export const businessPlansMonthly = [
  {
    value: "LIGHT FARE AND NO ADS",
    popular: false,
    label: "LIGHT FARE AND NO ADS",
    price: 5,
    has_sale: false,
    has_toggle: true,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT FARE AND NO ADS:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 3,
    included: [
      "pricing:plan:LIGHT FARE AND NO ADS:included1",
      "pricing:plan:LIGHT FARE AND NO ADS:included2",
    ],
    not_included: ["pricing:plan:LIGHT FARE AND NO ADS:not_included1"],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "LIGHT PRO",
    popular: true,
    label: "LIGHT PRO",
    price: 15,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 50,
    included: [
      "pricing:plan:LIGHT PRO:included1",
      "pricing:plan:LIGHT PRO:included2",
      "pricing:plan:LIGHT PRO:included3",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "PRO",
    popular: false,
    label: "PRO",
    price: 49,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: true,
      custom: false,
      min: 3,
      max: 150,
    },
    description: "pricing:plan:PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 250,
    included: [
      "pricing:plan:PRO:included1",
      "pricing:plan:PRO:included2",
      "pricing:plan:PRO:included3",
      "pricing:plan:PRO:included4",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "ENTERPRISE",
    enterprise: true,
    popular: false,
    label: "ENTERPRISE",
    price: null,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: true,
      custom: true,
    },
    description: "pricing:plan:ENTERPRISE:description",
    qr_codes: "pricing:plan:unlimited_qr_codes",
    included: [
      "pricing:plan:ENTERPRISE:included1",
      "pricing:plan:ENTERPRISE:included2",
      "pricing:plan:ENTERPRISE:included3",
      "pricing:plan:ENTERPRISE:included4",
      "pricing:plan:ENTERPRISE:included5",
    ],
    not_included: [],
    button: {
      type: "popup",
      prop: "helpCenter",
      label: "button:contact_us",
    },
  },
];

export const businessPlansYearly = [
  {
    value: "LIGHT FARE AND NO ADS",
    popular: false,
    label: "LIGHT FARE AND NO ADS",
    price: 5,
    has_sale: true,
    sale: 12,
    has_toggle: true,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT FARE AND NO ADS:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 3,
    included: [
      "pricing:plan:LIGHT FARE AND NO ADS:included1",
      "pricing:plan:LIGHT FARE AND NO ADS:included2",
    ],
    not_included: ["pricing:plan:LIGHT FARE AND NO ADS:not_included1"],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "LIGHT PRO",
    popular: true,
    label: "LIGHT PRO",
    price: 15,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: false,
    },
    description: "pricing:plan:LIGHT PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 50,
    included: [
      "pricing:plan:LIGHT PRO:included1",
      "pricing:plan:LIGHT PRO:included2",
      "pricing:plan:LIGHT PRO:included3",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "PRO",
    popular: false,
    label: "PRO",
    price: 49,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: true,
      custom: false,
      min: 3,
      max: 150,
    },
    description: "pricing:plan:PRO:description",
    qr_codes: "pricing:plan:dynamic_qr_code",
    qr_codes_count: 250,
    included: [
      "pricing:plan:PRO:included1",
      "pricing:plan:PRO:included2",
      "pricing:plan:PRO:included3",
      "pricing:plan:PRO:included4",
    ],
    not_included: [],
    button: {
      type: "link",
      label: "pricing:get_started",
    },
  },
  {
    value: "ENTERPRISE",
    enterprise: true,
    popular: false,
    label: "ENTERPRISE",
    price: null,
    has_sale: false,
    has_toggle: false,
    multi_users: {
      enabled: true,
      custom: true,
    },
    description: "pricing:plan:ENTERPRISE:description",
    qr_codes: "pricing:plan:unlimited_qr_codes",
    included: [
      "pricing:plan:ENTERPRISE:included1",
      "pricing:plan:ENTERPRISE:included2",
      "pricing:plan:ENTERPRISE:included3",
      "pricing:plan:ENTERPRISE:included4",
      "pricing:plan:ENTERPRISE:included5",
    ],
    not_included: [],
    button: {
      type: "popup",
      prop: "helpCenter",
      label: "button:contact_us",
    },
  },
];

export const plansTable = [
  {
    title: "pricing:table:group1:title",
    rows: [
      {
        title: "pricing:table:group1:item1:title",
        description: "pricing:table:group1:item1:description",
        prop: "dynamic_qr_codes",
        // checked|number|size
        type: "number",
      },
      {
        title: "pricing:table:group1:item2:title",
        description: "pricing:table:group1:item2:description",
        prop: "static_qr_codes",
        // checked|number|
        type: "limited-number",
      },
      {
        title: "pricing:table:group1:item3:title",
        description: "pricing:table:group1:item3:description",
        prop: "scans",
        // checked|number|
        type: "number",
      },
      {
        title: "pricing:table:group1:item4:title",
        description: "pricing:table:group1:item4:description",
        prop: "qr_templates",
        // checked|number|
        type: "checked",
      },
      {
        title: "pricing:table:group1:item5:title",
        description: "pricing:table:group1:item5:description",
        prop: "multiple_download_formats",
        // checked|number|
        type: "checked",
      },
      {
        title: "pricing:table:group1:item6:title",
        description: "pricing:table:group1:item6:description",
        prop: "limit_space",
        // checked|number|
        type: "size",
      },
    ],
  },
  {
    title: "pricing:table:group2:title",
    rows: [
      {
        title: "pricing:table:group2:item1:title",
        description: "pricing:table:group2:item1:description",
        prop: "tracking_date",
        // checked|number|size
        type: "checked",
      },
      {
        title: "pricing:table:group2:item2:title",
        description: "pricing:table:group2:item2:description",
        prop: "tracking_device",
        // checked|number|
        type: "checked",
      },
      {
        title: "pricing:table:group2:item3:title",
        description: "pricing:table:group2:item3:description",
        prop: "tracking_gps",
        // checked|number|
        type: "checked",
      },
    ],
  },
  {
    title: "pricing:table:group3:title",
    rows: [
      {
        title: "pricing:table:group3:item1:title",
        description: "pricing:table:group3:item1:description",
        prop: "gdpr",
        // checked|number|size
        type: "checked",
      },
      {
        title: "pricing:table:group3:item2:title",
        description: "pricing:table:group3:item2:description",
        prop: "sso",
        // checked|number|
        type: "checked",
      },
    ],
  },
  {
    title: "pricing:table:group4:title",
    rows: [
      {
        title: "pricing:table:group4:item1:title",
        description: "pricing:table:group4:item1:description",
        prop: "qr_design_customization",
        // checked|number|size
        type: "checked",
      },
      {
        title: "pricing:table:group4:item2:title",
        description: "pricing:table:group4:item2:description",
        prop: "page_templates",
        // checked|number|size|template_amount
        type: "template_amount",
      },
    ],
  },
  {
    title: "pricing:table:group5:title",
    rows: [
      {
        title: "pricing:table:group5:item1:title",
        description: "pricing:table:group5:item1:description",
        prop: "knowledge_base",
        // checked|number|size
        type: "checked",
      },
      {
        title: "pricing:table:group5:item2:title",
        description: "pricing:table:group5:item2:description",
        prop: "email_support",
        // checked|number|
        type: "checked",
      },
      {
        title: "pricing:table:group5:item3:title",
        description: "pricing:table:group5:item3:description",
        prop: "customer_manager",
        // checked|number|
        type: "checked",
      },
    ],
  },
];
